import ChildCard from 'components/Panel/LeftPanel/ChildCard'
import { Link, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AccountPageState } from '../../../layouts/AccountPagesLayout/index.state'
import useKidsSelector from '../../KidsSelector/useKidsSelector'
import Loader from '../../UI/Loader'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rammetto One', cursive;
  text-decoration: none;
`

const Title = styled.h1`
  color: #FFFFFF;
  font-size: 30px;
  margin-top: 28px;
  line-height: 30px;
  text-shadow: 0px 5px #afafaf, 0px 10px rgba(0, 0, 0, 0.3);
  // max-width: 280px;
  text-align: center;
`

const Kids = styled.div`
  width: 340px;
  > * > :first-child { margin-bottom: 15px; }
`


const StyledLink = styled(Link)`
  color: #FFFFFF;
  text-decoration: none;
`

const Students = () => {
  const { kids, fetch, loading } = useKidsSelector()
  useEffect (() => {
    fetch('cache-first')
  }, [])
  

  const urlParams = new URLSearchParams(window.location.search)
  const deepLink = urlParams.get('deepLink')
  const getLink = kid => deepLink ? deepLink.replace('{{username}}', kid.username) : `/student/${kid.username}`

  useEffect(() => {
    if ((kids || []).length === 1) {
      navigate(getLink(kids[0]))
    }
  }, [kids])

  return <Wrapper>
    <Title>Which Night Zookeeper?</Title>
    {
    loading
    ? <Loader color='#fff' />
    : <Kids>
      {kids.sort((a, b) => {
        const aField = a.nickname || a.username
        const bField = b.nickname || b.username
        if (aField < bField) return -1
        if (aField > bField) return 1
        return 0
      }).map((kid) => <StyledLink key={kid._id} to={getLink(kid)}><ChildCard key={kid._id} {...kid}/></StyledLink>)}
    </Kids>
    }
  </Wrapper>
}


export default (props) =>
  <AccountPageState.Provider>
    <Students {...props} />
  </AccountPageState.Provider>

