import React from "react"
import Layout from '../layouts/layout'
import StudentsPage from "../components/pages/StudentsPage"

const StudentPageWrapper = () => {
  if (typeof window === "undefined") return null

  return <Layout>
    <StudentsPage />
  </Layout>
}

export default StudentPageWrapper
